import { Component } from '@angular/core';
import {LoanApiServiceService} from './loan-api-service.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'sgloan';
  constructor(private loanapiservice: LoanApiServiceService){
    this.loanapiservice.bankList=[];
  }
}
