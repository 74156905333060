<div class="container-fluid px-0">
    <div class="row mx-0">
        <div class="col-12 px-0">
            <carousel [noPause]="false">
                <slide class="mx-auto">
                    <img src="assets/images/condo.jpg" alt="First slide" class="img-fluid">
                    <div class="carousel-caption d-none d-md-block">
                        <h3>Condomium Loan</h3>
                        <p></p>
                    </div>
                </slide>
                <slide class="mx-auto">
                    <img src="assets/images/hdb.jpg" alt="Second slide" class="img-fluid">
                    <div class="carousel-caption d-none d-md-block">
                        <h3 >HDB Loan</h3>
                        <p></p>
                    </div>
                </slide>
                <slide class="mx-auto">
                    <img src="assets/images/bungalow.jpg" alt="Third slide" class="img-fluid">
                    <div class="carousel-caption d-none d-md-block">
                        <h3>Landed Loan</h3>
                        <p></p>
                    </div>
                </slide>
            </carousel>
        </div>
    </div>
</div>