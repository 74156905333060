import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http'
import {Observable} from 'rxjs'


@Injectable({
  providedIn: 'root'
})
export class LoanApiServiceService {

  // private rootURL:string='http://127.0.0.1:8000/';
  private rootURL:string="https://oqgjtkf1r6.execute-api.ap-southeast-1.amazonaws.com/production/";
  public bankList:string[]; //buffer for bank list

  constructor(private http:HttpClient) {

   }

  public loadFullTable(
    bank: string,
    mortgageTerm: string, 
    loanstatus: string,
    flattype: string,
    flatstatus:string):Observable<any>{
    let routeURL='charting/tabledata/';
    const Options={
      params: new HttpParams()
      .set('institution',bank)
      .set('loanterm',mortgageTerm)
      .set('loanstatus',loanstatus)
      .set('flattype',flattype)
      .set('flatstatus',flatstatus)

    }
    return this.http.get(this.rootURL+routeURL, Options)
  }

  /**
   * loadBankList
   
   let routeURL = '/charting/institutionArray/'*/
  public loadBankList(): Observable<any> {
    let routeURL = 'charting/institutionArray/';
    return this.http.get(this.rootURL+routeURL);
    
  }

  /**
   * insertContact
   */
  public insertContact(contact: Object) {
    let routeURL = "charting/insertContact/";
    let header = new HttpHeaders({
      "Content-Type": "application/json"
    })
    console.log(contact)
    return this.http.post(this.rootURL+routeURL,JSON.stringify(contact), {headers: header});
    
  }
}
