import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms'
import { LoanApiServiceService } from './../loan-api-service.service'
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  @ViewChild('f', { static: false }) contactForm: NgForm;

  constructor(private loanAPI: LoanApiServiceService, private _snackBar: MatSnackBar) {
  }

  ngOnInit() {
  }

  public openSnackBar(message: string, action: string) {
    return this._snackBar.open(message, action, {
      duration: 5000,
    });
  }

  public submitContact(f: NgForm) {
    if (f.valid) {
      this.loanAPI.insertContact(f.value as Object).subscribe(
        data => {
          console.log(data)
          let snackBarRef: MatSnackBarRef<any> = (this.openSnackBar('Submitted', 'dismiss'));
          snackBarRef.onAction().subscribe(() => {
            // console.log('The snack-bar action was triggered!');
            snackBarRef.dismiss();
          });
        }
      )
    }
  }

}
