<div class="container-fluid mt-3 mx-2">
    <div class="row">
        <div class="col-md-4">
            <!-- put table in modal-->
            <!-- <table class="table  highlight-border ">
                <tr>
                    <th *ngFor="let label of lineChartLabels">{{label}}</th>
                </tr>
                <tr *ngFor="let d of lineChartData; let i=index" [class]="'line-'+i">
                    <td *ngFor="let label of lineChartLabels; let j=index">{{d && d.data[j]}}</td>
                </tr>
            </table> -->
            <div class="card">
                <div class="card-header text-primary bg-dark">
                    View Toggler
                </div>
                <div class="card-body">
                    <h6 class="card-title">Mortgage Term</h6>
                    <mat-radio-group class="toggle-radio-group" (click)="filterclick()" aria-label="Mortgage Term"
                        [(ngModel)]="mortgageTerm">
                        <mat-radio-button class="toggle-radio-button" value="fixed">Fixed</mat-radio-button>
                        <mat-radio-button class="toggle-radio-button" value="floating">Floating</mat-radio-button>
                    </mat-radio-group>
                    <h6 class="card-title">Loan Status</h6>
                    <mat-radio-group class="toggle-radio-group" (click)="filterclick()" aria-label="Loan Status"
                        [(ngModel)]="loanstatus">
                        <mat-radio-button class="toggle-radio-button" value="New">New</mat-radio-button>
                        <mat-radio-button class="toggle-radio-button" value="Refinancing">Refinancing</mat-radio-button>
                    </mat-radio-group>
                    <h6 class="card-title">Flat Type</h6>
                    <mat-radio-group class="toggle-radio-group" (click)="filterclick()" aria-label="Flat Type"
                        [(ngModel)]="flattype">
                        <mat-radio-button class="toggle-radio-button" value="HDB">HDB</mat-radio-button>
                        <mat-radio-button class="toggle-radio-button" value="Private">Private</mat-radio-button>
                    </mat-radio-group>
                    <h6 class="card-title">Flat Status</h6>
                    <mat-radio-group class="toggle-radio-group" (click)="filterclick()" aria-label="Flat Status"
                        [(ngModel)]="flatstatus">
                        <mat-radio-button class="toggle-radio-button" value="BUC">BUC</mat-radio-button>
                        <mat-radio-button class="toggle-radio-button" value="Completed">Completed</mat-radio-button>
                    </mat-radio-group>
                    <mat-form-field>
                        <mat-label>Institution</mat-label>
                        <mat-select [formControl]="institutions" (click)="filterclick()" multiple>
                            <mat-option *ngFor="let entry of institutionList" [value]="entry">{{entry}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <!-- <button class="btn btn-dark" (click)="randomize()">Randomize</button>
                    <button class="btn btn-dark" (click)="pushOne()">Push</button>
                    <button class="btn btn-dark" (click)="changeColor()">Recolor</button>
                    <button class="btn btn-dark" (click)="hideOne()">Remove UOB</button>
                    <button class="btn btn-dark" (click)="changeLabel()">Change Label</button> -->
                    <div>
                        <button mat-raised-button color="primary" (click)="refreshchart()">Apply</button>
                        <button *ngIf="contactBoolean" class="mx-1" mat-raised-button color="accent" (click)="contactNav()">Looks Good? Contact Us Now</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-8">
            <mat-chip-list aria-label="final selection">
                <mat-chip color="foreground" selected>{{mortgageTerm}}</mat-chip>
                <mat-chip color="foreground" selected>{{loanstatus}}</mat-chip>
                <mat-chip color="foreground" selected>{{flattype}}</mat-chip>
                <mat-chip color="foreground" selected>{{flatstatus}}</mat-chip>
                <mat-chip color="warn" selected *ngIf="refreshNow">click apply button to refresh</mat-chip>
            </mat-chip-list>
            <div style="display: block;">
                <canvas baseChart width="600" height="400" [datasets]="lineChartData" [labels]="lineChartLabels"
                    [options]="lineChartOptions" [colors]="lineChartColors" [legend]="lineChartLegend"
                    [chartType]="lineChartType" [plugins]="lineChartPlugins" (chartHover)="chartHovered($event)"
                    (chartClick)="chartClicked($event)"></canvas>
            </div>
        </div>
    </div>
</div>