<div class="container mt-3">
    <div class="card">
        <div class="card-header bg-dark text-primary"> Contact Form</div>
        <div class="card-body">
            <form (ngSubmit)="submitContact(f)" #f="ngForm">
                <mat-form-field class="contact-full-width">
                    <mat-label>Name</mat-label>
                    <input matInput placeholder="eg. Super Loan" required name="name" ngModel>
                    <mat-hint>Input your preferred name</mat-hint>
                </mat-form-field>
                <mat-form-field class="contact-full-width">
                    <mat-label>Email</mat-label>
                    <input matInput placeholder="user@example.com" required name="email" ngModel>
                    <mat-hint>Input your email</mat-hint>
                </mat-form-field>
                <mat-form-field class="contact-full-width">
                    <mat-label>Mobile</mat-label>
                    <input matInput placeholder="90908080" required name="mobile" ngModel>
                    <mat-hint>Input your mobile number</mat-hint>
                </mat-form-field>
                <div class="mt-3">
                    <button mat-raised-button color="primary" type="submit">Submit</button>
                </div>
            </form>
        </div>
    </div>
</div>