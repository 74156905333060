<nav class="navbar navbar-expand-md navbar-dark bg-dark">
    <a class="navbar-brand" [routerLink]="['']"  ><i class="fa fa-money fa-lg"></i> Super Mortgage</a>
    <button class="navbar-toggler" type="button" 
    (click)="isCollapsed = !isCollapsed"
    [attr.aria-expanded]="!isCollapsed" 
    aria-controls="collapseBasic">
      <span class="navbar-toggler-icon"></span>
    </button>
  
    <div class="collapse navbar-collapse" id="collapseBasic" [collapse]="isCollapsed" [isAnimated]="true">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['']" [routerLinkActiveOptions]="{exact:true}" routerLinkActive="active" >Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/chart']" routerLinkActive="active" >Explore Packages</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/contact']" routerLinkActive="active" >Contact Us</a>
        </li>
      </ul>
      <form class="form-inline my-2 my-lg-0">
        <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search">
        <button class="btn btn-outline-primary my-2 my-sm-0" type="submit">Search</button>
      </form>
    </div>
  </nav>