import { Component, OnInit, ViewChild } from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, BaseChartDirective, Label } from 'ng2-charts';
import * as pluginAnnotations from 'chartjs-plugin-annotation';
import * as pluginLabels from 'chartjs-plugin-datalabels'
import { LoanApiServiceService } from '../loan-api-service.service'
import { FormControl } from '@angular/forms';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { Observable } from 'rxjs'
import { Router } from '@angular/router'

@Component({
  selector: 'app-linechart',
  templateUrl: './linechart.component.html',
  styleUrls: ['./linechart.component.css']
})
export class LinechartComponent implements OnInit {

  public mortgageTerm: string = 'floating';
  public loanstatus: string = 'New';
  public flattype: string = 'Private';
  public flatstatus: string = 'BUC';
  public refreshNow: boolean = false;
  public institutions = new FormControl();
  public institutionList: string[];

  public contactBoolean: boolean = false;

  public lineChartData: ChartDataSets[] = [
    { data: [65, 59, 80, 81, 56, 55, 40], label: 'DBS' },
    { data: [28, 48, 40, 19, 86, 27, 90], label: 'UOB' },
    {
      data: [180, 480, 770, 90, 1000, 270, 400], label: 'OCBC',
      // yAxisID: 'y-axis-1' 
    }
  ];
  public lineChartLabels: Label[] = ['Year 1', 'Year 2', 'Year 3', 'Year 4', 'Year 5', 'Year 6', 'Year 7'];
  public lineChartOptions: (ChartOptions & { annotation: any } & { plugins: any }) = {
    responsive: true,
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [{}],
      yAxes: [
        {
          id: 'y-axis-0',
          position: 'left',
          ticks: {
            suggestedMin: 0.5,
          }

        },
        // {
        //   id: 'y-axis-1',
        //   position: 'right',
        //   gridLines: {
        //     color: 'rgba(255,0,0,0.3)',
        //   },
        //   ticks: {
        //     fontColor: 'red',
        //   }
        // }
      ]
    },
    annotation: {
      annotations: [
        {
          type: 'line',
          mode: 'vertical',
          scaleID: 'x-axis-0',
          value: 'Year 2',
          borderColor: 'orange',
          borderWidth: 2,
          label: {
            enabled: true,
            fontColor: 'orange',
            content: '2 year'
          }
        },
      ],
    },
    plugins: {
      datalabels: {
        color: '#343a40',
        align: "bottom" as "right",
      }
    }
  };
  public lineChartColors: Color[] = [
    { // grey
      backgroundColor: 'rgba(148,159,177,0.2)',
      borderColor: 'rgba(148,159,177,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // dark grey
      backgroundColor: 'rgba(77,83,96,0.2)',
      borderColor: 'rgba(77,83,96,1)',
      pointBackgroundColor: 'rgba(77,83,96,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,83,96,1)'
    },
    { // red
      backgroundColor: 'rgba(255,0,0,0.3)',
      borderColor: 'red',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    }
  ];
  public lineChartLegend = true;
  public lineChartType = 'line';
  public lineChartPlugins = [pluginAnnotations, pluginLabels];

  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

  constructor(private loanapiservice: LoanApiServiceService, private _snackBar: MatSnackBar, private router: Router) {
    // console.log(this.loanapiservice.bankList)
    if (this.loanapiservice.bankList.length === 0) {
      console.log("loading data...")
      this.loanapiservice.loadBankList().subscribe(
        data => {
          this.loanapiservice.bankList = data;
          this.institutionList = this.loanapiservice.bankList;
          this.institutions.setValue(this.institutionList);
          this.getTableData();
        }
      )
    } else {
      this.institutionList = this.loanapiservice.bankList;
      this.institutions.setValue(this.institutionList);
      this.getTableData();
    }

  }

  ngOnInit() {
    // console.log(this.institutionList);
    // window.onerror = function(errMsg, url, line, column, error){
    //   this.openSnackBar(String(errMsg).substr(0,10),'dismiss');
    // }

  }

  public openSnackBar(message: string, action: string) {
    return this._snackBar.open(message, action, {
      duration: 2500,
    });
  }

  /**
   * getTableData
   */
  public getTableData() {
    let bank = (<[]>this.institutions.value).join(',')
    this.loanapiservice.loadFullTable(bank, this.mortgageTerm, this.loanstatus, this.flattype, this.flatstatus).subscribe(
      data => {
        // console.log(data)
        let result = this.tableDataTransform(data);
        console.log(result);
        this.lineChartData = result[0];
        this.lineChartLabels = result[1];
      }
    )
  }

  public randomize(): void {
    for (let i = 0; i < this.lineChartData.length; i++) {
      for (let j = 0; j < this.lineChartData[i].data.length; j++) {
        this.lineChartData[i].data[j] = this.generateNumber(i);
      }
    }
    this.chart.update();
  }

  private generateNumber(i: number) {
    return Math.floor((Math.random() * (i < 2 ? 100 : 1000)) + 1);
  }

  // events
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public hideOne() {
    const isHidden = this.chart.isDatasetHidden(1);
    this.chart.hideDataset(1, !isHidden);
  }

  public pushOne() {
    this.lineChartData.forEach((x, i) => {
      const num = this.generateNumber(i);
      const data: number[] = x.data as number[];
      data.push(num);
    });
    this.lineChartLabels.push(`Year ${this.lineChartLabels.length + 1}`);
  }

  public changeColor() {
    this.lineChartColors[2].borderColor = 'green';
    this.lineChartColors[2].backgroundColor = `rgba(0, 255, 0, 0.3)`;
  }

  public changeLabel() {
    this.lineChartLabels[2] = ['1st Line', '2nd Line'];
    // this.chart.update();
  }

  /**
   * name
   */
  public refreshchart(): null {
    this.refreshNow = false;
    this.contactBoolean = true;
    console.log(this.institutions.value);
    try {
      this.getTableData();
      let snackBarRef: MatSnackBarRef<any> = (this.openSnackBar('Refreshed', 'dismiss'));
      snackBarRef.onAction().subscribe(() => {
        // console.log('The snack-bar action was triggered!');
        snackBarRef.dismiss();
      });
    } catch (e) {
      // this.openSnackBar(String(e).substr(0,10),'dismiss');
    }
    return;
  }

  /**
   * filterclick
   */
  public filterclick() {
    this.refreshNow = true;
    return;

  }

  /**
   * contactNav
   */
  public contactNav() {
    this.router.navigate(['/contact']);

  }

  /**
   * tableDataTransform
   */
  public tableDataTransform(data: []): Array<any> {
    // console.log(data);
    let result = new Object();
    let bank = [];
    let counter: number;
    for (let item of data) {
      if (bank.includes(item['institution'])) {
        (<[]>result[item['institution']]).push(item['interest']);
      } else {
        bank.push(item['institution']);
        result[<string>item['institution']] = [item['interest']];
      }

    }
    counter = result[bank[0]].length;
    let lableArray = [];

    for (let i = 0; i < counter; i++) {
      lableArray.push("Year " + (i + 1));
    }

    let formatResult = [];
    for (let bank of Object.keys(result)) {
      formatResult.push({ data: result[bank], label: bank })
    }

    return [formatResult, lableArray];
  }

}
